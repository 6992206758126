<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import seekerService from '../../services/api/seekerService';

export default {
  props: {
    profileData: {
      type: Object
    }
  },
  data: () => ({
    verifying: false,
    verifyingOtp: false,
    form: {
      phone: '',
      countryCode: ''
    },
    changePhoneToken: '',
    step: -1,
    OTP: [],
    resendIn: -1,
    steps: {
      changePhoneEnter: 'changePhoneEnter',
      changePhoneVerify: 'changePhoneVerify'
    }
  }),
  components: {
    VueTelInput
  },
  methods: {
    verify() {
      if (!this.form.phone) {
        this.$handleError('Please enter phone number');
        return;
      }

      let phoneNumber = this.form.phone + '';

      if (phoneNumber && phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.substring(1);
      }

      if (!phoneNumber.startsWith('+')) {
        phoneNumber = this.form.countryCode + phoneNumber;
      }

      phoneNumber = phoneNumber.replace(/[\s-+]/g, '');

      // console.log(phoneNumber);

      const formData = {
        phone: phoneNumber
      };

      this.verifying = true;

      if (this.step === 1) {
        seekerService
          .verifyPhone(formData)
          .then((res) => {
            this.$handleSuccess(res);
            this.step = 2;
            this.resendIn = 60;
          })
          .catch((e) => {
            this.$handleError(e);
          })
          .finally(() => {
            this.verifying = false;
          });
      } else if (this.step === this.steps.changePhoneEnter) {
        seekerService
          .changePhone(formData)
          .then((res) => {
            this.$handleSuccess(res);
            this.step = this.steps.changePhoneVerify;
            this.changePhoneToken = res.data.token;
            this.resendIn = 60;
          })
          .catch((e) => {
            this.$handleError(e);
          })
          .finally(() => {
            this.verifying = false;
          });
      }

      // console.log(formData);
    },

    verifyOTP() {
      // console.log(this.OTP);

      if (!this.OTP || this.OTP.length != 6) {
        this.$handleError('Please enter OTP');
        return;
      }

      const formData = {
        otp: this.OTP.join('')
      };

      this.verifyingOtp = true;

      if (this.step === 2) {
        seekerService
          .verifyPhoneOTP(formData)
          .then((res) => {
            this.$handleSuccess(res);
            console.log(res.data);
            this.step = 3;
          })
          .catch((e) => {
            this.$handleError(e);
          })
          .finally(() => {
            this.verifyingOtp = false;
          });
      } else if (this.step === this.steps.changePhoneVerify) {
        seekerService
          .changePhoneOTP({ ...formData, token: this.changePhoneToken })
          .then((res) => {
            this.$handleSuccess(res);
            console.log(res.data);
            this.step = 3;
          })
          .catch((e) => {
            this.$handleError(e);
          })
          .finally(() => {
            this.verifyingOtp = false;
          });
      }
    },

    handleCountryChange(data) {
      console.log(data);
      this.form.countryCode = '+' + data.dialCode;
    },
    setText({ target: { value } }, position) {
      const OTPinputs = document.querySelectorAll('.otp__input');

      // Targets the last input
      if (OTPinputs.length === position && value.length <= 1) {
        this.OTP = [...this.OTP];
        this.OTP[position - 1] = value;
      }

      // Targets all the inputs except the last
      if (OTPinputs.length !== position && value.length <= 1) {
        this.OTP = [...this.OTP];
        this.OTP[position - 1] = value;

        if (value.length === 1) {
          OTPinputs[position].focus();
          OTPinputs[position].setSelectionRange(0, 1);
        }
        // else if (value.length == 0 && position > 1) {
        //   OTPinputs[position - 1].focus();
        //   OTPinputs[position - 1].setSelectionRange(0, 1);
        // }
      }
    },
    continueSuccess() {
      location.reload();
    }
  },
  watch: {
    resendIn: function (newValue, oldValue) {
      if (newValue > 0) {
        setTimeout(() => {
          this.resendIn--;
        }, 1000);
      }
    },
    profileData: {
      handler(newVal, oldVal) {
        if (newVal.profile.isPhoneVerified) {
          this.step = 0;
        } else {
          this.step = 1;
        }
      },
      deep: true // Set deep to true for deep watching
    }
  },
  mounted() {
    if (this.profileData.profile.isPhoneVerified) {
      this.step = 0;
    } else {
      this.step = 1;
    }
  }
};
</script>

<template>
  <div class="sub-page">
    <h2>Phone Number Verification</h2>

    <hr class="mt-2" />
    <div class="pv-container">
      <div v-if="step === 0" class="text-center">
        <img src="/img/icons/check.svg" alt="" />
        <h2 class="text-center mt-2" style="color: rgba(131, 209, 123, 1)">
          Phone number verified
        </h2>

        <h2 class="my-2">
          Your phone number is <br />
          <strong class="number mt-2">
            +{{ profileData.profile.phone || form.phone }}
          </strong>
          <button
            class="ml-2"
            title="Change"
            @click="step = steps.changePhoneEnter"
          >
            <img class="edit-icon" src="/img/icons/edit-document.png" alt="" />
          </button>
        </h2>
      </div>

      <div v-if="step === 1 || step === steps.changePhoneEnter">
        <label for="">Enter your mobile number</label>
        <VueTelInput
          ref="tel"
          v-model="form.phone"
          @country-changed="handleCountryChange"
          class="mt-2"
        ></VueTelInput>
        <div class="mt-4 text-center">
          <div style="display: inline-block">
            <Button
              :disabled="verifying"
              class="w-20"
              :loading="verifying"
              @click="verify"
              btnType="button"
              theme="blue"
            >
              Verify
            </Button>
          </div>
        </div>
      </div>

      <div v-if="step === 2 || step === steps.changePhoneVerify">
        <div class="otp-container">
          <h2 class="otp-title text-center">
            A code has been sent to <br />
            <span class="otp-title--bold">
              {{ form.phone }}
            </span>
          </h2>
          <div class="px-8 mt-3">
            <label for="">Enter Code</label>

            <div class="d-flex mt-2">
              <div class="otp__box" v-for="(n, index) in 6" :key="index">
                <input
                  type="tel"
                  class="otp__input"
                  placeholder="-"
                  maxlength="1"
                  @input="setText($event, index + 1)"
                />
              </div>
            </div>

            <Button
              :disabled="verifyingOtp"
              :loading="verifyingOtp"
              @click="verifyOTP"
              btnType="button"
              theme="blue"
              style="width: 100%"
              class="mt-3"
            >
              Continue
            </Button>
          </div>
        </div>
        <p class="mt-2">
          Didn’t receive any code?
          <span :class="{ disabled: resendIn > 0 }">
            <span class="link" @click="verify">click to resent code</span>
            <template v-if="resendIn > 0">({{ resendIn }})</template>
          </span>
        </p>
      </div>

      <div v-if="step === 3">
        <div class="otp-container">
          <VueTelInput
            ref="tel"
            v-model="form.phone"
            @country-changed="handleCountryChange"
            class="mt-2 mx-2"
            disabled
          ></VueTelInput>
          <div class="px-8 mt-3 text-center">
            <img src="/img/icons/check.svg" alt="" />
            <h3 class="text-center mt-2" style="color: rgba(131, 209, 123, 1)">
              Phone number verified
            </h3>
            <Button
              @click="continueSuccess"
              btnType="button"
              theme="blue"
              style="width: 100%"
              class="my-3"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pv-container {
  max-width: 477px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
}

.otp-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5;
}

.otp-title--bold {
  font-weight: 700;
  font-size: 28px;
}

.otp-container {
  border: 1px solid #68686838;
  border-radius: 10px;
  padding: 22px;
}

span.link {
  cursor: pointer;
  color: #1aa7ec;
}

.d-flex {
  display: flex;
}

.otp__box {
  width: 44px;

  margin: 0 5px;
}

.otp__input {
  text-align: center;
  width: 100%;
  padding: 7px 2px;
  border: 0;
  border-bottom: 1px solid rgb(180, 180, 180);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.edit-icon {
  width: 16px;
  cursor: pointer;
}

.number {
  color: #1aa7ec;
  display: inline-block;
}
</style>
